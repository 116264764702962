@media screen and (max-width: 990px) {
    .details-container {
        flex-wrap: wrap;
    }

    .blog-image {
        flex-basis: 100%;
        width: auto;
    }

    .blog-desc {
        margin-left: 0px;
        margin-top: 20px;
        flex-basis: 100%;
    }

    .blog-title {
        font-size: 2rem;
    }

    .blog-preview {
        font-size: 1.3rem;
    }
}