.navbar {
    padding-left: 15px;

    @media (min-width: 576px) {
        padding-left: 75px;
    }
}

.nav-item {
    margin-right: 30px;
}

.last-margin {
    margin-right: 100px;
}

.active {
    color: rgb(129, 65, 255) !important;
}

.navbar-heading {
    margin-bottom: 0px;
}