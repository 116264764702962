.about-title {
    font-size: 3rem;
}

.about-description-container {
    flex-basis: 60%;
    padding-left: 20px;
    padding-right: 20px;
}

.about-hobbies-container {
    flex-basis: 30%;
    padding-left: 20px;
    padding-right: 20px;
}

.list-item {
    font-size: 1.5rem;
}

.interests {
    font-size: 1.8rem;
}


.about-description {
    font-size: 1.6rem;
}

.purple {
    color: rgb(129, 65, 255);
}

@media screen and (max-width: 990px) {
    .about-container {
        flex-wrap: wrap;
    }

    .about-description-container {
        flex-basis: 100%;
    }

    .about-hobbies-container {
        flex-basis: 100%;
        margin-top: 30px;
    }

    .about-title {
        font-size: 2rem;
    }

    .about-description{
        font-size: 1.3rem;
    }

    .interests {
        font-size: 1.5rem;
    }

    .list-item {
        font-size: 1.3rem;
    }

}