.post-container {
    flex-wrap: wrap;
    padding-left: 100px;
    padding-right: 100px;
}

.post-title {
    flex-basis: 100%;
    font-size: 3rem;
}

.post-body {
    flex-basis: 100%;
}

p {
    font-size: 1.3rem;
    width: 100%;
}

a {
    color: rgb(129, 65, 255);
}

h2 {
    flex-basis: 100%;
}

.content-image {
    max-width: 80%;
}

.content-image-container {
    flex-basis: 100%;
}

@media screen and (max-width: 990px) {
    .post-container {
        padding-left: 32px;
        padding-right: 32px;
    }

    .post-body {
        font-size: 1.3rem;
    }

    .post-title {
        font-size: 2rem;
    }

    .content-image {
        max-width: 100%;
    }

    p {
        font-size: 1.1rem;
    }

    h2 {
        font-size: 1.5rem;
    }
}