.contact {
    flex-basis: 100%;
}

.contact-container {
    flex-wrap: wrap;
}

.link {
    color: grey;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
    color: rgb(75, 11, 202);
}

@media screen and (max-width: 990px) {
    .contact-text {
        font-size: 2rem;
    }
}