.product-one-liner {
    font-size: 1.2rem;
    color: rgb(108, 108, 108);
}

.product-intro-container {
    flex-basis: 50%;
    padding: 25px;
}

.product-description {
    font-size: 1.6rem;
}

.product-description-container {
    flex-basis: 50%;
    padding: 25px;
}

.work-container {
    flex-wrap: wrap;
    margin-bottom: 90px;
}

.product-image {
    max-width: 30%;
    margin-right: 3em;
}

.btn {
    font-size: 1.3rem;
    border: 1px solid #048961;
    color: #048961;
}

.btn:hover {
    color: white;
    background-color: #048961;
}

.product-title{
    color: #048961;
    font-size: 3rem;
}

.product-image-container
{
    flex-basis: 100%;
}

@media screen and (max-width: 990px) {
    .work-container {
        flex-wrap: wrap;
    }

    .product-intro-container {
        flex-basis: 100%;
    }

    .product-description-container {
        padding-top: 0px;
        flex-basis: 100%;
    }

    .product-title {
        font-size: 2.5rem;
    }

    .product-one-liner {
        font-size: 1rem;
    }

    .product-description {
        font-size: 1.3rem;
    }

    .product-image {
        max-width: 50%;
        margin-right: 0;
    }


    .btn {
        font-size: 1.1rem;
    }
}