.heading-container {
    flex-basis: 100%;
    margin-bottom: 40px;
}

.heading {
    font-size: 2.5rem;
    padding-left: 12px;
}

.blog-container {
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
}

.blog-desc {
    flex-basis: 70%;
    margin-left: 40px;
}

.blog-title {
    font-size: 2.5rem;
}

.blog-image {
    flex-basis: 25%;
    /* width: auto; */
    width: 300px;
    pointer-events: all;
}

.blog-preview {
    font-size: 1.5rem;
}

.blog-link {
    text-decoration: none;
    color: #212529;
}

.spinner-container{
    flex-basis: 100%;
}

@media screen and (max-width: 990px) {
    .blog-container {
        padding-left: 20px;
        padding-right: 20px;
    }
}